import { Box, Typography, Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const PasswordSuccessMessage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box>
      <Typography level="h1" sx={{ marginBottom: '1rem', fontWeight: '600' }}>
        {t('reset_password.reset_successfull_heading')}
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          color: 'success.700',
          backgroundColor: 'success.100',
          padding: '1rem',
          borderRadius: '4px',
          marginBottom: '2rem',
        }}
      >
        {t('reset_password.reset_successfull_text')}
      </Typography>
      <Button
        color="neutral"
        sx={{
          width: '100%',
          borderRadius: '4px',
          paddingY: '8px',
          paddingX: '24px',
          marginTop: '16px',
          fontSize: '1rem',
          lineHeight: '1rem',
          fontWeight: '400',
          minHeight: '3rem',
        }}
        onClick={() => navigate('/')}
      >
        {t('reset_password.return_to_login')}
      </Button>
    </Box>
  );
};
