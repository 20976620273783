import { FormControl, FormLabel, Grid, Textarea, Typography } from '@mui/joy';
import { Controller, useFormContext } from 'react-hook-form';
import { TextInputField } from '../../TextInputField';
import { BasicDroneModelInput } from '../../../models/DroneModel/DroneModel.model';
import { useTranslation } from 'react-i18next';

export const DroneModelDetailsForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<BasicDroneModelInput>();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      {/* Name Field */}
      <Grid xs={12} sm={6}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextInputField
              label={t('drone_models.name')}
              required={true}
              {...field}
              error={errors.name?.message as string}
            />
          )}
        />
      </Grid>

      {/* Manufacturer Field */}
      <Grid xs={12} sm={6}>
        <Controller
          name="manufacturer"
          control={control}
          render={({ field }) => (
            <TextInputField
              label={t('drone_models.manufacturer')}
              required={true}
              {...field}
              error={errors.manufacturer?.message as string}
            />
          )}
        />
      </Grid>

      {/* Type Field */}
      <Grid xs={12} sm={6}>
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <TextInputField
              label={t('drone_models.type')}
              required={true}
              {...field}
              error={errors.type?.message as string}
            />
          )}
        />
      </Grid>

      {/* Description Field */}
      <Grid xs={12}>
        <FormControl required={true}>
          <FormLabel>{t('drone_models.description')}</FormLabel>
          <Controller
            name="description"
            control={control}
            render={({ field }) => <Textarea id="description" autoComplete="off" minRows={4} maxRows={4} {...field} />}
          />
          {errors.description && <Typography color="danger">{errors.description.message as string}</Typography>}
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default DroneModelDetailsForm;
