import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InvitationService } from '../services/InvitationService';
import { useTranslation } from 'react-i18next';

const AcceptOrgInvitation: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [message, setMessage] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    const handleAccept = async () => {
      if (!token) {
        setMessage(t('accept_organization.errors.invalid_invitation'));
        return;
      }

      try {
        const response = await InvitationService.acceptOrgInvitation(token);
        setMessage(response.message);
      } catch (error) {
        setMessage(t('accept_organization.errors.invalid_invitation'));
      }
    };

    handleAccept();
  }, [token]);

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold">{message || t('accept_organization.processing')}</h2>
    </div>
  );
};

export default AcceptOrgInvitation;
