import React, { forwardRef } from 'react';
import { FormControl, FormLabel, Input, FormHelperText } from '@mui/joy';
import { useTheme } from '@mui/joy';
import { SxProps } from '@mui/system';

interface Props {
  label: string;
  name: string;
  placeholder?: string;
  error?: string;
  value?: string;
  type?: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  classNames?: string;
  required?: boolean;
  autocomplete?: string;
  sx?: SxProps;
}

export const TextInputField = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      name,
      placeholder,
      error,
      value,
      type,
      disabled = false,
      required = false,
      onChange,
      classNames,
      autocomplete,
      sx,
    },
    ref,
  ) => {
    const theme = useTheme();
    return (
      <FormControl className="mb-4" error={!!error} ref={ref} required={required}>
        <FormLabel style={{ color: disabled ? theme.palette.neutral[400] : '' }}>{label}</FormLabel>
        <Input
          id={name}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          type={type}
          disabled={disabled}
          className={classNames}
          ref={ref}
          autoComplete={autocomplete || 'off'}
          sx={sx}
        />
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  },
);

TextInputField.displayName = 'TextInputField';
