import { useState } from 'react';
import { SessionService } from '../services/SessionService';
import { toast } from 'react-toastify';
import sessionStore from '../stores/SessionStore';
import { useTranslation } from 'react-i18next';
import { CreateAccountForm } from '../components/CreateAccountForm';
import { CreateAccountFormFields } from '../types/CreateAccountFormFields';

export const Signup = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<CreateAccountFormFields>({
    firstName: '',
    lastName: '',
    callSign: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState<Record<string, string>>({});

  const signUp = async () => {
    if (!sessionStore.csrfToken) {
      toast(t('signup_page.signup_failed'));
      return;
    }

    try {
      const signUpResp = await SessionService.signup(
        formData.firstName,
        formData.lastName,
        formData.callSign,
        formData.email,
        formData.password,
        sessionStore.csrfToken,
      );

      if (signUpResp.status !== 201) {
        throw new Error('Signup failed');
      }

      toast(signUpResp.message);
    } catch (error) {
      console.error('Error signing up:', error);
      toast(t('signup_page.signup_failed'));
    }
  };

  return (
    <CreateAccountForm
      disableEmail={false}
      buttonText={t('signup_page.sign_up')}
      formData={formData}
      setFormData={setFormData}
      errors={errors}
      setErrors={setErrors}
      onSubmit={signUp}
    />
  );
};
