import { Box } from '@mui/joy';
import { FC, ReactNode, PropsWithChildren } from 'react';

interface PageBodyProps extends PropsWithChildren {
  header?: ReactNode;
}

export const PageBody: FC<PageBodyProps> = ({ header, children }) => {
  return (
    <Box>
      {header && <Box>{header}</Box>}
      {/* Main content */}
      <Box>{children}</Box>
    </Box>
  );
};
