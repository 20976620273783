import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import DynamicTable from '../components/DynamicTable';
import TableHeader from '../components/TableHeader';
import GeneralModal from '../components/GeneralModal';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Chip from '@mui/joy/Chip';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';
import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import SearchIcon from '@mui/icons-material/Search';
import { CompanyService } from '../services/CompanyService';
import { UserService } from '../services/UserService';
import { UserRoleService } from '../services/UserRoleService';
import { RoleService } from '../services/RoleService';
import User from '../models/User';
import Invitation from '../models/Invitation';
import Role from '../models/Role';
import EmailInvitesModal from '../components/EmailInvitesModal';
import ContrastButton from '../components/ContrastButton';
import { toast } from 'react-toastify';
import lodash from 'lodash';
import { UserPermissionRow } from '../types/UserPermissionRow';
import { organizationStore } from '../stores/OrganizationStore';
import { useTranslation } from 'react-i18next';

const Settings = observer(() => {
  const { selectedOrg } = organizationStore;
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRows, setSelectedRows] = useState<UserPermissionRow[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<'manage' | 'permissions' | 'invite'>('manage');
  const [organizationData, setOrganizationData] = useState<{
    users: User[];
    invitations: Invitation[];
  }>({ users: [], invitations: [] });
  const [roles, setRoles] = useState<Role[]>([]);
  const [currentUser, setCurrentUser] = useState<User>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const { t } = useTranslation();

  const fetchOrganizationData = async () => {
    if (selectedOrg !== null) {
      try {
        const response = await CompanyService.getOrganizationDetails(selectedOrg);
        setOrganizationData(response.data);
        setSelectedRows([]);
      } catch (error) {
        toast.error(t('settings.errors.fetch'));
      }
    }
  };

  useEffect(() => {
    fetchOrganizationData();
  }, [selectedOrg]);

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      const user = JSON.parse(userData);
      setCurrentUser(user);
    }
  }, []);

  const fetchRoles = async () => {
    try {
      const roleData = await RoleService.getRoles();
      setRoles(roleData);
    } catch (error) {
      toast.error(t('settings.error.fetch_roles'));
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const columns: { header: string; key: keyof UserPermissionRow }[] = [
    { header: 'User', key: 'name' },
    { header: 'Email', key: 'email' },
    { header: 'Permissions', key: 'permissions' },
  ];

  const data = React.useMemo(() => {
    const users = organizationData.users.map((user) => ({
      id: user.id,
      name: `${lodash.capitalize(user.first_name)} ${lodash.capitalize(user.last_name)}`,
      email: user.email,
      permissions: user.roles.map((role) => (
        <Chip key={role} variant="soft">
          {role} {user.is_owner ? ' (Owner)' : ''}
        </Chip>
      )),
    }));

    const invitations = organizationData.invitations.map((invitation) => ({
      id: invitation.user,
      email: invitation.user_email,
      permissions: invitation.roles.map((role) => (
        <Chip key={role} variant="soft">
          {role} (Invite Sent)
        </Chip>
      )),
    }));

    const sortedData = [...users, ...invitations];
    if (currentUser) {
      const loggedInUserIndex = sortedData.findIndex((user) => user.id === currentUser.id);
      if (loggedInUserIndex > -1) {
        const [loggedInUser] = sortedData.splice(loggedInUserIndex, 1);
        sortedData.unshift(loggedInUser);
      }
    }

    return sortedData;
  }, [organizationData, currentUser]);

  const filteredData = data.filter((row) =>
    columns.some((column) =>
      row[column.key as keyof typeof row]?.toString().toLowerCase().includes(searchQuery.toLowerCase()),
    ),
  );

  const handleSelectionChange = (selectedRows: any[]) => {
    setSelectedRows(selectedRows);
  };

  const handleOpenModal = (type: 'manage' | 'permissions' | 'invite') => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleRemoveUsers = async () => {
    const idsToDelete = selectedRows.map((row) => row.id);

    try {
      await UserService.bulkRemoveOrganization(idsToDelete, selectedOrg!);
      toast.success(t('settings.user_removed'));
      setSelectedRows([]);
      setIsModalOpen(false);
      fetchOrganizationData();
    } catch (error) {
      toast.error(t('settings.errors.remove_users'));
    }
  };

  const handleUpdatePermissions = async () => {
    const idsToUpdate = selectedRows.map((row) => row.id);
    const roleId = selectedRole ? parseInt(selectedRole) : null;

    if (roleId) {
      try {
        await UserRoleService.bulkUpdateRoles(idsToUpdate, roleId);
        toast.success(t('settings.user_perms_updated'));
        setSelectedRows([]);
        setIsModalOpen(false);
        fetchOrganizationData();
      } catch (error) {
        toast.error(t('settings.update_perms'));
      }
    }
  };

  const handleDropdownOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (roleId: string) => {
    setSelectedRole(roleId);
    setAnchorEl(null);
    setModalType('permissions');
    setIsModalOpen(true);
  };

  const modalContent =
    modalType === 'manage' ? (
      <Typography sx={{ minWidth: '400px' }}>
        {t('settings.bulk_user_remove_confirmation', {
          count: selectedRows.length,
          msg: selectedRows.length > 1 ? 'users' : 'user',
        })}
      </Typography>
    ) : modalType === 'permissions' && selectedRole ? (
      <Typography sx={{ minWidth: '400px' }}>
        {t('settings.change_role_message', {
          role: roles.find((role) => role.id === parseInt(selectedRole))?.name,
        })}
      </Typography>
    ) : modalType === 'invite' ? (
      <EmailInvitesModal onClose={handleCloseModal} onInviteSuccess={fetchOrganizationData} />
    ) : null;

  const modalTitle =
    modalType === 'manage'
      ? t('settings.manage_rows')
      : modalType === 'permissions' && selectedRole
        ? selectedRows.length === 1
          ? t('settings.change_role_message', {
              role: roles.find((role) => role.id === parseInt(selectedRole))?.name,
            })
          : t('settings.bulk_role_change_confirmation', { count: selectedRows.length })
        : t('settings.invite_users');

  const modalActions =
    modalType === 'manage' ? (
      <>
        <Button variant="plain" color="neutral" onClick={handleCloseModal}>
          {t('edit_profile.cancel')}
        </Button>
        <Button variant="solid" color="danger" onClick={handleRemoveUsers}>
          {t('settings.remove_users', {
            rows: selectedRows.length,
            msg: selectedRows.length > 1 ? 'users' : 'user',
          })}
        </Button>
      </>
    ) : modalType === 'permissions' ? (
      <>
        <Button variant="plain" color="neutral" onClick={handleCloseModal}>
          {t('edit_profile.cancel')}
        </Button>
        <ContrastButton onClick={handleUpdatePermissions}>{t('settings.confirm')}</ContrastButton>
      </>
    ) : null;

  return (
    <>
      <TableHeader
        title={
          selectedRows.length > 0
            ? t('settings.users_select', {
                count: selectedRows.length,
                msg: selectedRows.length > 1 ? 'Users' : 'User',
              })
            : t('navigation.users_permissions')
        }
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexGrow: 1, ml: 2 }}>
          <Input
            placeholder={t('settings.search')}
            startDecorator={<SearchIcon />}
            value={searchQuery}
            sx={{
              '--Input-minHeight': '2.5rem',
              maxWidth: '25rem',
              width: '100%',
            }}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', gap: 1 }}>
          {selectedRows.length > 0 ? (
            <>
              <Dropdown>
                <MenuButton color="neutral" variant="plain" size="md" onClick={handleDropdownOpen}>
                  {t('settings.update_perms')}
                </MenuButton>
                <Menu>
                  {roles.length > 0 ? (
                    roles.map((role) => (
                      <MenuItem key={role.id} onClick={() => handleMenuItemClick(role.id.toString())}>
                        {role.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>{t('settings.no_perms')}</MenuItem>
                  )}
                </Menu>
              </Dropdown>
              <Button color="danger" variant="soft" size="md" onClick={() => handleOpenModal('manage')}>
                {selectedRows.length > 0
                  ? t('settings.remove_users', { rows: selectedRows.length, msg: 'Users' })
                  : t('settings.remove_users', { rows: '\b', msg: 'User' })}
              </Button>
            </>
          ) : (
            <Button color="neutral" variant="plain" size="md" onClick={() => handleOpenModal('invite')}>
              {t('settings.invite_users')}
            </Button>
          )}
        </Box>
      </TableHeader>

      <DynamicTable
        columns={columns}
        data={filteredData}
        selectedRows={selectedRows}
        onSelectionChange={handleSelectionChange}
        currentUser={currentUser}
      />

      <GeneralModal
        open={isModalOpen}
        title={modalTitle}
        content={modalContent}
        actions={modalActions}
        onClose={handleCloseModal}
      />
    </>
  );
});

export default Settings;
