import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SessionService } from '../services/SessionService';
import { useTranslation } from 'react-i18next';

export default function VerifyEmail() {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>(t('verify_email.verifying'));
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    if (token) {
      verifyEmailToken(token);
    }
  }, [token]);

  const verifyEmailToken = async (token: string) => {
    try {
      const response = await SessionService.verifyEmail(token);

      setMessage(response.message);
    } catch (error: any) {
      switch (error.constructor.name) {
        case 'ValidationException':
          setMessage(t('verify_email.error'));
          break;
        case 'ServerException':
          setMessage(t('general_errors.server_error'));
          break;
        default:
          setMessage(t('general_errors.unknown_error'));
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-black relative overflow-hidden">
      <div className="bg-black w-full border-6 z-10 absolute top-0 flex items-center pl-6 flex-wrap">
        <img className="h-12 w-auto" src="/images/logos/logo.png" alt={t('user_list.sifly_logo')} />
        <img className="h-20 w-auto" src="/images/logos/forwardArrow.png" alt={t('user_list.sifly_arrow')} />

        <span className="text-white  md:ml-6">{t('verify_email.intelligent_aerial_robotics')}</span>
      </div>

      <footer className="bg-black w-full border-6 z-10 absolute bottom-0 flex p-6 text-white">
        <div>
          <p>
            © {new Date().getFullYear()} Sifly. {t('verify_email.all_rights_reserved')}
          </p>
        </div>
      </footer>
      <video autoPlay muted loop className="absolute w-auto min-w-full min-h-full max-w-none">
        <source src="/videos/siflylogin.mp4" type="video/mp4" />
        {t('verify_email.errors.video_tag_not_supported')}
      </video>

      <div className="w-full max-w-md p-8 space-y-8 bg-black bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg z-10 m-4">
        <div className="text-center">
          <h5 className="mt-6 text-3xl text-white">{message || t('verify_email.verifying')}</h5>
        </div>
        <div>
          <button
            type="button"
            onClick={() => navigate('/')}
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {t('verify_email.login')}
          </button>
        </div>
      </div>
    </div>
  );
}
