import { TFunction } from 'i18next';
import * as yup from 'yup';
import {
  BasicDroneModelInput,
  ConfigOnlyDroneModelInput,
  FullDroneModelInput,
} from '../../models/DroneModel/DroneModel.model';

export const getDroneModelDetailsFormSchema = (t: TFunction): yup.ObjectSchema<BasicDroneModelInput> => {
  return yup.object().shape({
    name: yup
      .string()
      .required(t('drone_models.validation.name.required'))
      .min(3, t('drone_models.validation.name.min')),
    manufacturer: yup
      .string()
      .required(t('drone_models.validation.manufacturer.required'))
      .min(2, t('drone_models.validation.manufacturer.min')),
    type: yup.string().required(t('drone_models.validation.type.required')),
    description: yup.string().max(300, t('drone_models.validation.description.max')),
  });
};

export const getDroneModelConfigFormSchema = (t: TFunction): yup.ObjectSchema<ConfigOnlyDroneModelInput> => {
  return yup.object().shape({
    configuration: yup
      .string()
      .required(t('drone_models.validation.config.required'))
      .test('is-valid-json', t('drone_models.validation.config.invalid_json_format'), (value) => {
        try {
          JSON.parse(value);
          return true;
        } catch {
          return false;
        }
      }),
  });
};

export const getDroneModelAllFormSchema = (t: TFunction): yup.ObjectSchema<FullDroneModelInput> => {
  const detailsSchema = getDroneModelDetailsFormSchema(t);
  const configSchema = getDroneModelConfigFormSchema(t);
  const completeSchema = detailsSchema.concat(configSchema) as yup.ObjectSchema<FullDroneModelInput>;
  return completeSchema;
};
