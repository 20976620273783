import { Box, useTheme } from '@mui/joy';
import { ReactNode } from 'react';

interface SimpleHeaderLayoutProps {
  children: ReactNode;
  controlComponent?: ReactNode;
}

export const SimpleHeaderLayout: React.FC<SimpleHeaderLayoutProps> = ({ children, controlComponent }) => {
  const theme = useTheme();

  const logoSrc = theme.palette.mode === 'dark' ? 'images/logos/siflyLogo.svg' : 'images/logos/siflyBlackLogo.png';

  return (
    <Box className="min-h-screen flex flex-col">
      <Box className="border-b border-opacity-30 flex justify-between">
        {logoSrc && <img className="h-5 w-28 font-medium m-5" src={logoSrc} alt="Logo" />}
        {controlComponent}
      </Box>

      {children}
    </Box>
  );
};
