import React, { forwardRef } from 'react';
import { FormControl, FormLabel, Select, FormHelperText, Option } from '@mui/joy';
import { SxProps } from '@mui/system';

interface Props {
  label: string;
  name: string;
  value: string;
  error?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  options: { value: string; label: string }[];
  sx?: SxProps;
}

export const SelectField = forwardRef<HTMLDivElement, Props>(
  ({ label, name, value, error, onChange, options, sx }, ref) => {
    return (
      <FormControl className="mb-4" error={!!error} sx={sx} ref={ref}>
        <FormLabel>{label}</FormLabel>
        <Select id={name} name={name} value={value} onChange={(event, newValue) => onChange(newValue!)} sx={sx}>
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  },
);
