import ResponsiveLayout from '../components/layout/ResponsiveLayout';
import Settings from './Settings';

export default function UserPermissions() {
  return (
    <ResponsiveLayout>
      <Settings></Settings>
    </ResponsiveLayout>
  );
}
