import { Box } from '@mui/joy';
import ReactJson from 'react-json-view';
import { DroneModel } from '../../models/DroneModel/DroneModel.model';
import { FC } from 'react';

interface DroneModelConfigurationViewProps {
  drone: DroneModel;
}

const DroneModelConfigurationView: FC<DroneModelConfigurationViewProps> = ({ drone }) => {
  let config = null;
  if (drone.configuration) {
    config = JSON.parse(drone.configuration);
  }

  return (
    <Box maxHeight="600px" overflow="auto">
      {config && (
        <ReactJson
          src={config}
          name={null}
          collapsed={false}
          enableClipboard={false}
          displayDataTypes={false}
          displayObjectSize={false}
          theme="rjv-default"
        />
      )}
    </Box>
  );
};

export default DroneModelConfigurationView;
