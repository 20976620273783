import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import sessionStore from '../stores/SessionStore';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import { ValidationException } from '../services/Exceptions';
import { TextInputField } from '../components/TextInputField';
import { useTheme } from '@mui/joy';
import loginFormSchema from '../schema/loginFormSchema';
import * as Yup from 'yup';
import { Mode, useCustomTheme } from '../contexts/ThemeContext';

interface FormValues {
  email: string;
  password: string;
}

function Login() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState<FormValues>({
    email: searchParams.get('email') || '',
    password: '',
  });

  const token = searchParams.get('token') || '';
  const org = searchParams.get('org') || '';
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { changeTheme } = useCustomTheme();

  useEffect(() => {
    if (sessionStore.isAuthenticated) {
      navigate('/dashboard', { replace: true });
    }
  }, [navigate]);

  const validateForm = async (): Promise<boolean> => {
    const schema = loginFormSchema(t);
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (validationErrors) {
      if (validationErrors instanceof Yup.ValidationError) {
        const formErrors: Record<string, string> = {};
        validationErrors.inner.forEach((error) => {
          if (error.path) {
            formErrors[error.path] = error.message;
          }
        });

        setErrors(formErrors);
      }
      return false;
    }
  };

  const handleLogin = async () => {
    const isValid = await validateForm();
    if (!isValid) return;

    try {
      await sessionStore.login({ email: formData.email, password: formData.password });
      let redirectTo = '';

      if (token && org) {
        redirectTo = `/organization-list?token=${token}&org=${org}`;
      } else {
        redirectTo = '/dashboard';
      }

      changeTheme(sessionStore.user?.color_scheme_preference as Mode);
      navigate(redirectTo, { replace: true });
    } catch (error) {
      if (error instanceof ValidationException) {
        toast.error(t('login_page.errors.invalid_credentials'));
      } else {
        console.error('Failed to login', error);
        toast.error(t('login_page.login_failed'));
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="min-h-screen flex flex-col sm:flex-row" style={{ background: theme.palette.neutral[100] }}>
      <div className="absolute top-0 right-0 p-4">
        <LanguageSwitcher />
      </div>
      <div className="relative sm:w-3/5 flex-shrink-0">
        <img
          className="absolute top-0 left-0 h-5 w-28 font-medium m-5 z-10"
          src="images/logos/logo.png"
          alt="Sifly Logo"
        />
        <video autoPlay muted loop className="h-[50vh] sm:min-h-full max-w-full object-fill sm:object-none">
          <source src="/videos/siflylogin.mp4" type="video/mp4" />
        </video>
      </div>

      <div className="flex items-center justify-center sm:min-h-[90vh] p-6 sm:w-2/5">
        <div className="max-w-[28rem]">
          <h2
            className="text-4xl leading-[3rem] font-semibold mb-6"
            style={{ color: theme.palette.neutral[800], fontFamily: theme.fontFamily.display }}
          >
            {t('login_page.sign_in_to_your_account')}
          </h2>
          <form
            className="mt-8 space-y-6"
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin();
            }}
          >
            <TextInputField
              label={t('login_page.email_address')}
              name="email"
              placeholder={t('login_page.email_address')}
              value={formData.email}
              error={errors.email}
              onChange={handleInputChange}
              classNames="w-full px-4 py-3 border rounded-md text-lg min-h-12"
            />
            <TextInputField
              label={t('login_page.password')}
              name="password"
              placeholder={t('login_page.password')}
              type="password"
              value={formData.password}
              error={errors.password}
              onChange={handleInputChange}
              classNames="w-full px-4 py-3 border rounded-md text-lg min-h-12"
            />

            <button
              type="submit"
              className="cursor-pointer w-full bg-black text-white rounded-md hover:bg-gray-700 transition duration-200 py-2 px-6 mt-4 text-base leading-4 font-normal h-12"
            >
              {t('login_page.log_in')}
            </button>

            <div className="flex items-center justify-between">
              <span className="flex justify-center font-normal text-base">
                <Link to={'/reset-password'} className="underline" style={{ color: theme.palette.neutral[700] }}>
                  {t('login_page.reset_password')}
                </Link>
              </span>

              <span className="flex justify-center font-normal text-base">
                <p className="text-black mr-2">{t('login_page.dont_have_account')} </p>
                <Link to={'/signup'} className="underline" style={{ color: theme.palette.neutral[700] }}>
                  {t('login_page.create_one')}
                </Link>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default observer(Login);
