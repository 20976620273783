import i18n from 'i18next';
import sessionStore from '../stores/SessionStore';

export const checkAndSetLanguage = () => {
  const user = sessionStore.user;
  const preferredLanguage = user?.preferred_language || 'en';

  if (i18n.language !== preferredLanguage) {
    i18n.changeLanguage(preferredLanguage);
  }
};
