import Invitation, { InvitationResponse } from '../models/Invitation';
import { apiClient } from './ApiService';

const sendOrgInvitation = async (user: number, content_type: string, object_id: number): Promise<Invitation> => {
  try {
    return await apiClient.post<Invitation>(
      '/account/send-invitation/',
      {
        user,
        content_type,
        object_id,
      },
      {},
      true,
    );
  } catch (error) {
    throw error;
  }
};

const acceptOrgInvitation = async (token: string): Promise<{ message: string }> => {
  try {
    return await apiClient.get<{ message: string }>(`/account/accept-invitation/${token}/`);
  } catch (error) {
    console.error('Error accepting invitation:', error);
    throw error;
  }
};

const validateInvitation = async (
  token: string,
  email: string,
): Promise<{ message: string; status: number; has_password: boolean; is_verified: boolean }> => {
  try {
    return await apiClient.get<{
      status: number;
      message: string;
      has_password: boolean;
      is_verified: boolean;
    }>(`/account/validate-invitation?token=${token}&email=${email}`);
  } catch (error) {
    console.error('Error validating invitation:', error);
    throw error;
  }
};

const sendMultipleOrgInvitations = async (
  emails: string[],
  content_type: string,
  object_id: number,
  role_id: number,
): Promise<InvitationResponse> => {
  try {
    return await apiClient.post<InvitationResponse>('/account/send-invitation/', {
      emails,
      content_type,
      object_id,
      role_id,
    });
  } catch (error) {
    throw error;
  }
};

export const InvitationService = {
  sendOrgInvitation,
  acceptOrgInvitation,
  sendMultipleOrgInvitations,
  validateInvitation,
};
