import './App.css';
import AppRoutes from './routes/routes';
import { CssBaseline } from '@mui/joy';
import { I18nextProvider } from 'react-i18next';
import { i18n, i18nPromise } from './i18n';
import { FC, useEffect, useState } from 'react';
import { ThemeProvider } from './contexts/ThemeContext';
import ThemedToastContainer from './components/ThemedToastContainer';
import '@fontsource/barlow/100.css';
import '@fontsource/barlow/200.css';
import '@fontsource/barlow/300.css';
import '@fontsource/barlow/400.css';
import '@fontsource/barlow/500.css';
import '@fontsource/barlow/600.css';
import '@fontsource/barlow/700.css';
import '@fontsource/barlow/800.css';
import '@fontsource/barlow/900.css';

import '@fontsource/be-vietnam-pro/100.css';
import '@fontsource/be-vietnam-pro/200.css';
import '@fontsource/be-vietnam-pro/300.css';
import '@fontsource/be-vietnam-pro/400.css';
import '@fontsource/be-vietnam-pro/500.css';
import '@fontsource/be-vietnam-pro/600.css';
import '@fontsource/be-vietnam-pro/700.css';
import '@fontsource/be-vietnam-pro/800.css';
import '@fontsource/be-vietnam-pro/900.css';
import { SelectedOrgProvider } from './contexts/SelectedOrgContext';
import { useLocation } from 'react-router-dom';
import { checkAndSetLanguage } from './general-utilities/languageUtils';
import ThemeManager from './components/ThemeManager';

function App() {
  const [isInitialized, setIsInitialized] = useState(false);
  const location = useLocation();

  useEffect(() => {
    i18nPromise.then(() => {
      setIsInitialized(true);
    });

    checkAndSetLanguage();

    const handleStorageChange = () => {
      checkAndSetLanguage();
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    checkAndSetLanguage();
  }, [location]);

  if (!isInitialized) {
    // This is a temporary loading screen. Discuss with Deyge to come up with a full page loading component. This should not go to production.
    return <div>Loading translations...</div>;
  }

  return (
    <>
      <I18nextProvider i18n={i18n}>
        <ThemedToastContainer />
        <CssBaseline />
        <AppRoutes />
      </I18nextProvider>
    </>
  );
}

const ThemeWrappedApp: FC = () => {
  return (
    <ThemeProvider>
      <SelectedOrgProvider>
        <ThemeManager />
        <App />
      </SelectedOrgProvider>
    </ThemeProvider>
  );
};

export default ThemeWrappedApp;
