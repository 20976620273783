// src/components/LanguageSwitcher.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div>
      <select onChange={changeLanguage} defaultValue={i18n.language}>
        <option value="en">{t('login_page.english')}</option>
        <option value="fr">{t('login_page.french')}</option>
        <option value="es">{t('login_page.spanish')}</option>
      </select>
    </div>
  );
};

export default LanguageSwitcher;
