import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Typography from '@mui/joy/Typography';
import ModalClose from '@mui/joy/ModalClose';
import { Box } from '@mui/joy';

interface GeneralModalProps {
  open: boolean;
  title: string;
  content: React.ReactNode;
  actions: React.ReactNode;
  onClose: () => void;
}

const GeneralModal: React.FC<GeneralModalProps> = ({ open, title, content, actions, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        aria-labelledby="general-modal-title"
        aria-describedby="general-modal-description"
        sx={{ maxWidth: 600 }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} onClick={onClose} />
        <Typography id="general-modal-title" level="h2">
          {title}
        </Typography>
        <Box sx={{ mt: 2 }}>{content}</Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>{actions}</Box>
      </ModalDialog>
    </Modal>
  );
};

export default GeneralModal;
