// src/general-utilities/serializers/DroneModelSerializer.ts

import { trimStrings } from '../../general-utilities/string-utils';
import {
  BasicDroneModelInput,
  ConfigOnlyDroneModelInput,
  DroneModel,
  DroneModelBackend,
  DroneModelCreate,
  DroneModelEditBasic,
  DroneModelEditConfig,
  FullDroneModelInput,
} from '../../models/DroneModel/DroneModel.model';

/**
 * Serializes FullDroneModelInput to DroneModelCreate for backend creation.
 * Excludes 'id', 'createdAt', and 'updatedAt'.
 * @param input - The full drone model input from the frontend form.
 * @returns The payload for creating a drone model in the backend.
 */
export const serializeDroneModelCreateToBackend = (input: FullDroneModelInput): DroneModelCreate => {
  const trimmedInput = trimStrings(input);
  return {
    name: trimmedInput.name,
    manufacturer: trimmedInput.manufacturer,
    type: trimmedInput.type,
    description: trimmedInput.description,
    configuration: trimmedInput.configuration, // Assuming config is already a JSON string
  };
};

/**
 * Serializes BasicDroneModelInput along with 'id' to DroneModelEdit for backend editing.
 * Excludes 'createdAt', 'updatedAt', and 'config'.
 * @param id - The unique identifier of the drone model to edit.
 * @param input - The basic drone model input from the frontend form.
 * @returns The payload for editing a drone model in the backend.
 */
export const serializeDroneModelEditBasicToBackend = (id: string, input: BasicDroneModelInput): DroneModelEditBasic => {
  const trimmedInput = trimStrings(input);
  return {
    id,
    name: trimmedInput.name,
    manufacturer: trimmedInput.manufacturer,
    type: trimmedInput.type,
    description: trimmedInput.description,
  };
};

/**
 * Serializes ConfigOnlyDroneModelInput along with 'id' to DroneModelEdit for backend config update.
 * Only updates the 'config' field.
 * @param id - The unique identifier of the drone model to edit.
 * @param input - The config-only drone model input from the frontend form.
 * @returns The payload for updating the drone model's config in the backend.
 */
export const serializeDroneModelEditConfigToBackend = (
  id: string,
  input: ConfigOnlyDroneModelInput,
): DroneModelEditConfig => {
  return {
    id,
    configuration: input.configuration, // Assuming config is a JSON string
    // Other fields can remain undefined or omitted
  };
};

/**
 * Deserializes DroneModelBackend to DroneModel for frontend usage.
 * Converts 'createdAt' and 'updatedAt' from strings to Date objects.
 * @param backend - The drone model data received from the backend.
 * @returns The drone model suitable for frontend usage.
 */
export const serializeDroneModelFromBackend = (backend: DroneModelBackend): DroneModel => {
  return {
    id: backend.id,
    name: backend.name,
    manufacturer: backend.manufacturer,
    type: backend.type,
    description: backend.description,
    configuration: backend.configuration, // Assuming frontend expects a JSON string; adjust if needed
    createdAt: new Date(backend.created_at),
    updatedAt: new Date(backend.updated_at),
  };
};

export {};
