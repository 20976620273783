import React, { useEffect, useState } from 'react';
import { FormControl, FormLabel, Select, Option, Button } from '@mui/joy';
import User from '../models/User';
import Role from '../models/Role';
import { RoleService } from '../services/RoleService';
import { UserService } from '../services/UserService';
import { UserRoleService } from '../services/UserRoleService';
import { useTranslation } from 'react-i18next';

const RoleAssignment: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [usersData, rolesData] = await Promise.all([UserService.getUsers(), RoleService.getRoles()]);
        setUsers(usersData);
        setRoles(rolesData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleUserChange = (value: string | null) => {
    setSelectedUser(value);
  };

  const handleRoleChange = (value: string | null) => {
    setSelectedRole(value);
  };

  const handleSubmit = async () => {
    if (selectedUser !== null && selectedRole !== null) {
      try {
        await UserRoleService.assignRole(Number(selectedUser), selectedRole);
        alert(t('role_assignment.successful'));
      } catch (error) {
        console.error('Error assigning role:', error);
      }
    }
  };

  const isFormValid = selectedUser !== null && selectedRole !== null;

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-full max-w-sm bg-white p-8 rounded-lg shadow-lg text-center">
        <FormControl className="mb-4">
          <FormLabel>{t('role_assignment.user')}</FormLabel>
          <Select
            value={selectedUser ?? ''}
            onChange={(e, value) => handleUserChange(value)}
            placeholder={t('role_assignment.select_user')}
          >
            {users.map((user) => (
              <Option key={user.id} value={String(user.id)}>
                {user.email}
              </Option>
            ))}
          </Select>
        </FormControl>

        <FormControl className="mb-4">
          <FormLabel>{t('role_assignment.role')}</FormLabel>
          <Select
            value={selectedRole ?? ''}
            onChange={(e, value) => handleRoleChange(value)}
            placeholder={t('role_assignment.select_role')}
          >
            {roles.map((role) => (
              <Option key={role.id} value={role.name}>
                {role.name}
              </Option>
            ))}
          </Select>
        </FormControl>

        <div className="flex justify-center mt-4">
          <Button variant="solid" color="primary" disabled={!isFormValid} onClick={handleSubmit} className="px-5 py-2">
            {t('role_assignment.assign')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RoleAssignment;
