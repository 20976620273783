import React, { ReactNode } from 'react';
import { Box, Breadcrumbs, Typography } from '@mui/joy';
import { BreadcrumbItem } from '../../models/BreadcrumbItem';
import { Link } from 'react-router-dom';

interface BreadcrumbLayoutProps {
  breadcrumbItems: BreadcrumbItem[];
  children: ReactNode;
  withSideNavigation?: boolean; // if this component is used in a page with side navigation: true, otherwise default: false
  controls?: ReactNode;
}

const BreadcrumbLayout: React.FC<BreadcrumbLayoutProps> = ({
  breadcrumbItems,
  children,
  withSideNavigation = true,
  controls,
}) => (
  <Box padding={withSideNavigation ? 0 : 2}>
    <Box display={controls ? 'flex' : 'block'} justifyContent="center" alignItems="flex-start">
      <Breadcrumbs sx={{ paddingTop: '0' }}>
        {breadcrumbItems.map((breadcrumb, index) => {
          if (!breadcrumb.link) {
            return (
              <Typography level="h4" key={index}>
                {breadcrumb.text}
              </Typography>
            );
          }

          if (breadcrumb.link) {
            return (
              <Link key={index} to={breadcrumb.link}>
                <Typography level="h4">{breadcrumb.text}</Typography>
              </Link>
            );
          }
        })}
      </Breadcrumbs>
      {controls && <Box ml="auto">{controls}</Box>}
    </Box>
    <Box mt={2}>{children}</Box>
  </Box>
);

export default BreadcrumbLayout;
