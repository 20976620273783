import { useState, useCallback, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { ServerException, SiflyError, ValidationException } from '../services/Exceptions';
import { ApiResult } from '../models/ApiResult';
import { TFunction } from 'i18next';

const getErrorMessage = (error: Error, t: TFunction): string => {
  if (error instanceof ValidationException) {
    throw new Error('ValidationException is not handled, check the error handling logic');
  } else if (error instanceof ServerException) {
    return t('general_errors.server_error');
  }
  return t('general_errors.unknown_error');
};

export interface CallServiceParams<Args, T> {
  serviceFunction: (args: Args) => Promise<ApiResult<T>>;
  args: Args;
  onValidationError?: (error: ValidationException) => void;
}

export type CallServiceResult<T> = {
  success: boolean;
  data?: T;
  error?: ValidationException | Error;
};

/**
 * Generic custom hook to handle API service calls with standardized response handling.
 * @param t - Translation function for messages.
 * @returns An object containing the loading state and a callService function.
 */
type UseService = (t: TFunction) => {
  loading: boolean;
  callService: <Args, T>(params: CallServiceParams<Args, T>) => Promise<CallServiceResult<T>>;
};

export const useApiService: UseService = (t) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleError = useCallback(
    <Args, T>(error: Error, params: CallServiceParams<Args, T>) => {
      if (error instanceof ValidationException) {
        params.onValidationError ? params.onValidationError(error) : toast.error(t('validationFailed'));
      } else {
        const errorMessage = getErrorMessage(error, t);
        toast.error(errorMessage || t('unexpectedError'));
      }
    },
    [t],
  );

  const callService = useCallback(
    async <Args, T>(params: CallServiceParams<Args, T>): Promise<CallServiceResult<T>> => {
      setLoading(true);
      try {
        const response = await params.serviceFunction(params.args);
        if (!response.success) {
          handleError(response.error, params);
        }
        return response;
      } catch (error: unknown) {
        if (error instanceof Error) {
          handleError(error, params);
          return { success: false, error };
        }
        return { success: false, error: new Error('Unknown error') };
      } finally {
        setLoading(false);
      }
    },
    [handleError],
  );

  return { loading, callService };
};
