import { FormProvider, useForm } from 'react-hook-form';
import { BasicDroneModelInput, DroneModel } from '../../../models/DroneModel/DroneModel.model';
import { Box, Button } from '@mui/joy';
import DroneModelDetailsForm from './DroneModelDetailsForm';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDroneModelDetailsFormSchema } from '../droneModelFormSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { DroneModelService } from '../../../services/DroneModelService';
import { serializeDroneModelEditBasicToBackend } from '../../../services/serializers/DroneModelSerializer';
import { useApiService } from '../../../hooks/useAPIService';
import { toast } from 'react-toastify';

interface DroneModelDetailsEditProps {
  initialData: DroneModel; // If provided, the form will be in edit mode
  onSuccessfulSave: (drone: DroneModel) => void; // Callback after successful submission
  onCancel: () => void; // Callback when the user cancels the form
}
const DroneModelDetailsEdit: FC<DroneModelDetailsEditProps> = ({ initialData, onSuccessfulSave, onCancel }) => {
  const { t } = useTranslation();
  const { loading, callService } = useApiService(t);
  const yupSchema = getDroneModelDetailsFormSchema(t);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const methods = useForm({
    defaultValues: {
      name: initialData?.name || '',
      manufacturer: initialData?.manufacturer || '',
      type: initialData?.type || '',
      description: initialData?.description || '',
    },
    resolver: yupResolver(yupSchema),
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data: BasicDroneModelInput) => {
    setIsSubmitting(true);
    try {
      let updatedDrone: DroneModel;
      const serilizedFormData = serializeDroneModelEditBasicToBackend(initialData.id, data);
      const response = await callService({
        serviceFunction: DroneModelService.updateDroneModelBasic,
        args: serilizedFormData,
      });

      if (response.success && response.data) {
        onSuccessfulSave(response.data);
      } else {
        toast.error(t('drone_models.errors.update_drone_model'));
      }
    } catch (err: unknown) {
      console.error('Error submitting form:', err); // TODO: Handle error and display translation
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '90%', // Full height card
        }}
      >
        <DroneModelDetailsForm />
        <Box display="flex" gap={2} mt={2}>
          <Button variant="solid" color="neutral" type="submit" disabled={isSubmitting}>
            {t('general_button_labels.save_changes')}
          </Button>
          <Button variant="outlined" color="neutral" onClick={onCancel}>
            {t('general_button_labels.cancel')}
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default DroneModelDetailsEdit;
