import { useEffect } from 'react';
import sessionStore from '../stores/SessionStore';
import { redirect } from 'react-router-dom';

const Logout = () => {
  useEffect(() => {
    if (sessionStore.isAuthenticated) {
      sessionStore.logout();
    } else {
      redirect('/login');
    }
  }, []);
  return null;
};

export default Logout;
