import User from '../models/User';
import { apiClient } from './ApiService';
import { MULTIPLE_USERS_REMOVE_ORGANIZATION } from '../constants/Users';

const getUsers = async (): Promise<User[]> => {
  try {
    return await apiClient.get<User[]>('/account/users', {}, true);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

const updateUser = async (userId: number, userData: Partial<User>): Promise<User> => {
  try {
    return await apiClient.put<User>(`/account/users/${userId}/`, userData, {}, true);
  } catch (error) {
    throw error;
  }
};

const changeUsernamePassword = async (userId: number, old_password: string, password: string): Promise<void> => {
  try {
    await apiClient.put<void>(`/account/users/${userId}/change_password/`, { old_password, password }, {}, true);
  } catch (error) {
    throw error;
  }
};

const removeOrganization = async (userId: number): Promise<void> => {
  try {
    await apiClient.patch<void>(`/account/users/${userId}/remove_organization/`, {}, {}, true);
  } catch (error) {
    console.error('Error removing organization:', error);
    throw error;
  }
};

const bulkRemoveOrganization = async (userIds: number[], orgId: number): Promise<void> => {
  try {
    return await apiClient.delete(MULTIPLE_USERS_REMOVE_ORGANIZATION, {
      ids: userIds,
      org_id: orgId,
    });
  } catch (error) {
    console.error('Error removing organization from users:', error);
    throw error;
  }
};

const sendResetPasswordEmail = async (email: string): Promise<User> => {
  try {
    return await apiClient.post<User>(`/account/request-reset-password/`, { email });
  } catch (error) {
    throw error;
  }
};

const resetPasswordRequest = async (token: string, new_password: string): Promise<User> => {
  try {
    return await apiClient.post<User>(`/account/reset-password/`, { token, new_password });
  } catch (error) {
    throw error;
  }
};

export const UserService = {
  getUsers,
  updateUser,
  changeUsernamePassword,
  removeOrganization,
  bulkRemoveOrganization,
  sendResetPasswordEmail,
  resetPasswordRequest,
};
