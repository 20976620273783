import { apiClient } from './ApiService';
import { USER_PASSWORD_UPDATE } from '../constants/UserPassword';
import { CreateAccountFormFields } from '../types/CreateAccountFormFields';

const updatePassword = async (values: CreateAccountFormFields, token: string): Promise<boolean> => {
  try {
    await apiClient.patch(USER_PASSWORD_UPDATE, {
      firstName: values.firstName,
      lastName: values.lastName,
      callSign: values.callSign,
      email: values.email,
      password: values.password,
      token,
    });
    return true;
  } catch (error) {
    throw error;
  }
};

export const UpdatePasswordService = { updatePassword };
