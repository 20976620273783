import { FC, ReactElement } from 'react';
import { Card, CardContent, Button, Box, Typography } from '@mui/joy';

// Define the props interface
interface EditableCardProps {
  viewComponent: ReactElement;
  editComponent: ReactElement;
  title: string;
  isEditing: boolean;
  setEdit: (arg0: boolean) => void;
}

// EditableCard Functional Component
const EditableCard: FC<EditableCardProps> = ({ viewComponent, editComponent, isEditing, setEdit, title }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        maxWidth: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        padding: 2,
      }}
    >
      <CardContent sx={{ flexGrow: 1, maxHeight: '500px', overflow: 'hidden' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 2,
          }}
        >
          <Typography level="title-md">{title}</Typography>
          {!isEditing && (
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => {
                setEdit(true);
              }}
            >
              Edit
            </Button>
          )}
        </Box>
        {isEditing ? editComponent : viewComponent}
      </CardContent>
    </Card>
  );
};

export default EditableCard;
