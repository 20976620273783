export const trimStrings = <T extends Record<string, any>>(input: T): T => {
  const trimmed: Partial<T> = {};
  for (const key in input) {
    if (typeof input[key] === 'string') {
      trimmed[key] = input[key].trim();
    } else {
      trimmed[key] = input[key];
    }
  }
  return trimmed as T;
};
