import { apiClient } from './ApiService';
import { LANGUAGES_URL } from '../constants/Language';

interface LanguageResponse {
  code: string;
  name: string;
}

const getLanguages = async (): Promise<LanguageResponse[]> => {
  try {
    const response = await apiClient.get<LanguageResponse[]>(LANGUAGES_URL, {}, false);
    return response;
  } catch (error) {
    console.error('Error fetching languages:', error);
    throw error;
  }
};

export const LanguageService = { getLanguages };
