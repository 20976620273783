import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const NotFound: FC = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h1>{t('not_found_page.errors.404_not_found')}</h1>
      <p>{t('not_found_page.errors.page_does_not_exist')}</p>
    </div>
  );
};

export default NotFound;
