import React, { ReactNode } from 'react';
import { Box, Grid } from '@mui/joy';
import { Navigation2 as Navigation } from './navigation/Navigation2';

interface TwoColumnLayoutProps {
  children: ReactNode;
}

const TwoColumnLayout: React.FC<TwoColumnLayoutProps> = ({ children }) => (
  <Grid container height="100vh">
    {/* TODO add a max width */}
    <Grid xs={12} sm={4} md={2.5}>
      <Box padding={3} boxShadow="lg" sx={{ backgroundColor: 'background.surface', height: '100%' }}>
        <Navigation />
      </Box>
    </Grid>
    <Grid xs={12} sm={8} md={9.5} paddingTop={3} paddingLeft={4} paddingRight={4}>
      <Box>{children}</Box>
    </Grid>
  </Grid>
);

export default TwoColumnLayout;
