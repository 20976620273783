import React from 'react';
import { ListItem, ListItemButton, ListItemContent, ListItemDecorator, Typography } from '@mui/joy';
import { Link } from 'react-router-dom';
import { SxProps } from '@mui/material';

interface SideNavLinkItemProps {
  icon: React.ReactNode;
  title: string;
  toLink: string;
  isActive?: boolean;
}

const SideNavLinkItem: React.FC<SideNavLinkItemProps> = ({ icon, title, toLink, isActive = false }) => {
  const localIcon = icon ? icon : null;
  const sxStyle: SxProps = {
    margin: '0',
  };
  if (isActive) {
    sxStyle.backgroundColor = 'neutral.100';
  }
  return (
    <ListItem sx={{ padding: '0' }}>
      <ListItemButton component={Link} to={toLink} sx={sxStyle}>
        <ListItemDecorator>{localIcon}</ListItemDecorator>
        <ListItemContent>
          <Typography level="body-md" sx={{ color: 'text.tertiary' }}>
            {title}
          </Typography>
        </ListItemContent>
      </ListItemButton>
    </ListItem>
  );
};

export default SideNavLinkItem;
