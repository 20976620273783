import { makeAutoObservable } from 'mobx';
import User from '../models/User';
import { SessionService } from '../services/SessionService';
import { redirect } from 'react-router-dom';
import { ValidationError } from 'yup';

class SessionStore {
  user: User | null = null;
  csrfToken: string | null = null;
  isAuthenticated: boolean = false;
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
    this.hydrateFromStorage();
    this.fetchCSRFToken();
  }

  setUser(user: User | null) {
    this.user = user;
    this.setIsAuthenticated(!!user);
    this.saveSession();
  }

  setIsAuthenticated(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
  }

  setCsrfToken(csrfToken: string | null) {
    this.csrfToken = csrfToken;
    this.saveSession();
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  login({ email, password }: { email: string; password: string }) {
    if (!this.csrfToken) {
      throw new ValidationError('CSRF token not set');
    }
    return SessionService.login(email, password).then((resp) => {
      this.setUser(resp.data.user);
      this.setIsAuthenticated(true);
      this.saveSession();
      this.fetchCSRFToken();
    });
  }

  logout() {
    if (!this.csrfToken) {
      throw new ValidationError('CSRF token not set');
    }
    SessionService.logout(this.csrfToken).then(() => {
      this.setIsAuthenticated(false);
      this.clearSession();
      this.fetchCSRFToken();
      redirect('/login');
    });
  }

  saveSession() {
    if (this.user) {
      localStorage.setItem('user', JSON.stringify(this.user));
    } else {
      localStorage.removeItem('user');
    }

    if (this.csrfToken) {
      localStorage.setItem('csrfToken', this.csrfToken);
    } else {
      localStorage.removeItem('csrfToken');
    }
  }

  fetchCSRFToken() {
    try {
      SessionService.getCSRFToken().then((resp) => {
        this.setCsrfToken(resp.csrfToken || '');
      });
    } catch (error) {
      // TODO: Handle error
      console.error('Failed to fetch CSRF token', error);
    }
  }

  validateSession() {
    try {
      SessionService.validateSession();
    } catch (error) {
      if (error instanceof Error && error.constructor.name === 'InvalidSessionError') {
        this.clearSession();
        redirect('/login');
      } else {
        // TODO: Handle other errors
        console.error('Failed to validate session', error);
      }
    }
  }

  clearSession() {
    this.setUser(null);
    this.setCsrfToken(null);
    this.setIsAuthenticated(false);
  }

  hydrateFromStorage() {
    const storedUser = localStorage.getItem('user');
    const storedCsrfToken = localStorage.getItem('csrfToken');
    if (storedUser && storedCsrfToken) {
      this.setUser(JSON.parse(storedUser)); // User and token will automatically save session
      this.setCsrfToken(storedCsrfToken);
      this.validateSession(); // Immediately validate the session with the server
    } else {
      this.setIsLoading(false);
    }
  }
}
const sessionStore = new SessionStore();
export default sessionStore;
