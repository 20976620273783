import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Grid, Avatar } from '@mui/joy';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import User from '../../models/User';
import { UserService } from '../../services/UserService';
import { toast } from 'react-toastify';
import { TextInputField } from '../../components/TextInputField';
import { SelectField } from '../../components/SelectField';
import { useTranslation } from 'react-i18next';
import { checkAndSetLanguage } from '../../general-utilities/languageUtils';
import getEditDetailsSchema from '../../schema/editDetailsSchema';
import sessionStore from '../../stores/SessionStore';
import { LanguageService } from '../../services/LanguageService';
import { UserProfileCard } from '../../components/UserProfileCard';

interface UserDetailsFormProps {
  userData: User | null;
  isEditing: boolean;
  handleEditDetails: () => void;
  handleCancelEdit: () => void;
}

interface UserDetailsFormData {
  fullName: string;
  email: string;
  preferred_language: string;
}

export const UserDetailsForm: React.FC<UserDetailsFormProps> = ({
  userData,
  isEditing,
  handleEditDetails,
  handleCancelEdit,
}) => {
  const { t } = useTranslation();
  const yupSchema = getEditDetailsSchema(t);
  const [languageOptions, setLanguageOptions] = useState<{ value: string; label: string }[]>([]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      fullName: '',
      email: '',
      preferred_language: '',
    },
    resolver: yupResolver(yupSchema),
  });

  useEffect(() => {
    reset({
      fullName: `${userData?.first_name || ''} ${userData?.last_name || ''}`,
      email: userData?.email || '',
      preferred_language: userData?.preferred_language || 'en',
    });
  }, [userData, reset]);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const languageData = await LanguageService.getLanguages();
        const mappedLanguages = languageData.map((lang) => ({
          value: lang.code,
          label: lang.name,
        }));
        setLanguageOptions(mappedLanguages);
      } catch (error) {
        console.error('Error fetching languages:', error);
        toast.error(t('edit_profile.errors.fetch_languages'));
      }
    };

    fetchLanguages();
  }, []);

  const onSubmit: SubmitHandler<UserDetailsFormData> = async (data: UserDetailsFormData) => {
    try {
      const [firstName, lastName] = data.fullName.split(' ');

      const updatedUser = {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        email: data.email.trim(),
        preferred_language: data.preferred_language,
      };

      const response = await UserService.updateUser(userData!.id, updatedUser);
      sessionStore.setUser(response);
      handleCancelEdit();
      checkAndSetLanguage();
      toast.success(t('edit_profile.account_details_updated'));
    } catch (error) {
      toast.error(`${t('edit_profile.errors.account_details_update')}: ${error}`);
    }
  };

  return (
    <UserProfileCard>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        {isEditing ? (
          <Typography level="h3">{t('edit_profile.your_details')}</Typography>
        ) : (
          <Typography level="h3">{t('edit_profile.account_details')}</Typography>
        )}
        {!isEditing && (
          <Button variant="outlined" color="neutral" onClick={handleEditDetails}>
            {t('edit_profile.edit_details')}
          </Button>
        )}
      </Box>

      {isEditing ? (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid xs={12}>
            <Avatar src="https://i.pravatar.cc/150?img=13" sx={{ width: 140, height: 140 }} />
          </Grid>

          {/* Full Name Field */}
          <Grid xs={12} md={6} sx={{ py: 0 }}>
            <Controller
              name="fullName"
              control={control}
              render={({ field }) => (
                <TextInputField
                  label={t('edit_profile.full_name')}
                  {...field}
                  error={errors.fullName ? errors.fullName.message : ''}
                />
              )}
            />
          </Grid>

          {/* Language Field */}
          <Grid xs={12} md={6} sx={{ py: 0 }}>
            <Controller
              name="preferred_language"
              control={control}
              render={({ field }) => (
                <SelectField
                  label={t('edit_profile.language')}
                  {...field}
                  options={languageOptions}
                  error={errors.preferred_language ? errors.preferred_language.message : ''}
                />
              )}
            />
          </Grid>

          {/* Email Field */}
          <Grid xs={12} md={6} sx={{ py: 0 }}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextInputField
                  label={t('edit_profile.email')}
                  {...field}
                  error={errors.email ? errors.email.message : ''}
                />
              )}
            />
          </Grid>

          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2 }}>
            <Button onClick={handleSubmit(onSubmit)} color="neutral" disabled={isSubmitting}>
              {isSubmitting ? t('edit_profile.saving') : t('edit_profile.save_changes')}
            </Button>
            <Button variant="outlined" color="neutral" onClick={handleCancelEdit}>
              {t('edit_profile.cancel')}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid xs={6} md={3}>
            <Typography>{t('edit_profile.avatar')}</Typography>
          </Grid>
          <Grid xs={6} md={9}>
            <Avatar src="https://i.pravatar.cc/150?img=13" size="lg" />
          </Grid>
          <Grid xs={6} md={3}>
            <Typography>{t('edit_profile.full_name')}</Typography>
          </Grid>
          <Grid xs={6} md={9}>
            <Typography>{userData?.first_name + ' ' + userData?.last_name}</Typography>
          </Grid>
          <Grid xs={6} md={3}>
            <Typography>{t('edit_profile.language')}</Typography>
          </Grid>
          <Grid xs={6} md={9}>
            <Typography>
              {languageOptions.find((lang) => lang.value === userData?.preferred_language)?.label || 'English'}
            </Typography>
          </Grid>
          <Grid xs={6} md={3}>
            <Typography>{t('edit_profile.email')}</Typography>
          </Grid>
          <Grid xs={6} md={9}>
            <Typography>{userData?.email}</Typography>
          </Grid>
        </Grid>
      )}
    </UserProfileCard>
  );
};
