import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, FormLabel, Button, Select, Option, Typography } from '@mui/joy';
import ChipsInputField from './ChipsInputField';
import '../EmailInvitesModal.css';
import { RoleService } from '../services/RoleService';
import Role from '../models/Role';
import { InvitationService } from '../services/InvitationService';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { organizationStore } from '../stores/OrganizationStore';
import { useTranslation } from 'react-i18next';

export interface FormValues {
  emailInput?: string;
  role?: string;
}

interface EmailInvitesModalProps {
  onClose: () => void;
  onInviteSuccess: () => void;
}

const EmailInvitesModal: React.FC<EmailInvitesModalProps> = ({ onClose, onInviteSuccess }) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [isFetchingRoles, setIsFetchingRoles] = useState<boolean>(true);
  const { selectedOrg } = organizationStore;
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    emailInput: Yup.string().email(t('invite_modal.errors.invalid_email')),
    role: Yup.string(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      emailInput: '',
      role: '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const selectedRole = watch('role');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setRoles(await RoleService.getRoles());
        setIsFetchingRoles(false);
      } catch (error) {
        toast.error(t('invite_modal.errors.fetch_data'));
      }
    };

    fetchData();
  }, []);

  const onSubmit = async (data: FormValues) => {
    if (selectedOrg === null) {
      toast.error(t('invite_modal.errors.org_selection'));
      return;
    }

    try {
      const roleId = Number(data.role);
      await InvitationService.sendMultipleOrgInvitations(emails, 'organization', selectedOrg, roleId);
      toast.success(t('invite_modal.invite_sent'));
      onClose();
      onInviteSuccess();
    } catch (error) {
      toast.error(t('invite_modal.errors.sending_invite'));
      onClose();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="email-invites-modal">
      {errors.emailInput && (
        <Typography color="danger" sx={{ marginBottom: 1 }}>
          {errors.emailInput.message}
        </Typography>
      )}
      <FormControl>
        <Controller
          name="emailInput"
          control={control}
          render={({ field }) => (
            <ChipsInputField<FormValues>
              items={emails}
              setItems={setEmails}
              triggerValidation={(name) => trigger(name as 'emailInput')}
              clearInput={() => setValue('emailInput', '')}
              field={field}
              chipSx={{ margin: 0.5, size: 'lg', bgcolor: '#f0f4f7' }}
              chipDeleteSx={{ color: '#000000' }}
              containerSx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                gap: 1,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 'md',
              }}
              placeholder={t('invite_modal.invite_others')}
            />
          )}
        />
      </FormControl>
      <div className="actions-wrapper">
        <div className="role-wrapper">
          <FormLabel htmlFor="role-select">{t('invite_modal.add_as')}</FormLabel>
          <div className="role-select-wrapper">
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value || ''}
                  onChange={(event, newValue) => setValue('role', newValue ?? '')}
                  sx={{ minWidth: '12rem' }}
                  slotProps={{
                    listbox: {
                      sx: {
                        width: '100%',
                      },
                    },
                  }}
                  id="role-select"
                  disabled={isFetchingRoles}
                >
                  {roles.map((role, index) => (
                    <Option key={index} value={role.id}>
                      {role.name}
                    </Option>
                  ))}
                </Select>
              )}
            />
            <Button
              type="submit"
              variant="solid"
              disabled={emails.length === 0 || !selectedRole}
              sx={{ backgroundColor: 'black', color: 'white', paddingY: '0.75rem' }}
            >
              {t('invite_modal.send_invites')}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default observer(EmailInvitesModal);
