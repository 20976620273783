import { Organization } from '../models/Company';
import { apiClient } from './ApiService';
import { OrganizationUsers } from '../models/Company';

const getOrganizations = async (): Promise<Organization[]> => {
  try {
    return await apiClient.get<Organization[]>('/account/companies', {}, false);
  } catch (error) {
    console.error('Error fetching organizations:', error);
    throw error;
  }
};

const createOrganization = async (owner_id: number, name: string): Promise<Organization> => {
  try {
    return await apiClient.post<Organization>('/account/companies/', { owner_id, name });
  } catch (error) {
    console.error('Error creating orgaization:', error);
    throw error;
  }
};

const getUserOrganizations = async (user_id: number): Promise<any> => {
  try {
    return await apiClient.get<Organization[]>(`/account/user-organizations/${user_id}`, {}, false);
  } catch (error) {
    throw error;
  }
};

const getOrganizationDetails = async (orgId: number): Promise<OrganizationUsers> => {
  try {
    return await apiClient.get<OrganizationUsers>(`/account/organizations/${orgId}/users-and-invitations/`, {}, false);
  } catch (error) {
    console.error(`Error fetching details for organization ${orgId}:`, error);
    throw error;
  }
};

export const CompanyService = {
  getOrganizations,
  createOrganization,
  getUserOrganizations,
  getOrganizationDetails,
};
