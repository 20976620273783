import * as React from 'react';
import Table from '@mui/joy/Table';
import Checkbox from '@mui/joy/Checkbox';
import IconButton from '@mui/joy/IconButton';
import Box from '@mui/joy/Box';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/joy';
import { UserPermissionRow } from '../types/UserPermissionRow';
import User from '../models/User';

interface TableColumn {
  header: string;
  key: keyof UserPermissionRow;
  render?: (value: any, row: UserPermissionRow) => React.ReactNode;
}

interface DynamicTableProps {
  columns: TableColumn[];
  data: any[];
  selectedRows: UserPermissionRow[];
  onSelectionChange?: (selectedRows: UserPermissionRow[]) => void;
  currentUser?: User;
}

const DynamicTable: React.FC<DynamicTableProps> = ({ columns, data, selectedRows, onSelectionChange, currentUser }) => {
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    setSelected(selectedRows.map((row) => generateKey(row)));
  }, [selectedRows]);

  const generateKey = (row: UserPermissionRow) => {
    const keyPart1 = row[columns[0]?.key] || 'missing';
    const keyPart2 = row[columns[1]?.key] || 'fallback';
    return `${keyPart1}-${keyPart2}`;
  };

  const mapKeysToRows = (keys: readonly string[]): UserPermissionRow[] => {
    return keys.map((key) => data.find((row) => generateKey(row) === key)!);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = event.target.checked
      ? data.filter((row) => !(currentUser && row.id === currentUser.id)).map((n) => generateKey(n))
      : [];

    setSelected(newSelected);
    if (onSelectionChange) {
      onSelectionChange(mapKeysToRows(newSelected));
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, key: string, row: UserPermissionRow) => {
    if (currentUser && row.id === currentUser.id) {
      return;
    }

    const selectedIndex = selected.indexOf(key);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key);
    } else {
      newSelected = selected.filter((selectedKey) => selectedKey !== key);
    }

    setSelected(newSelected);

    if (onSelectionChange) {
      onSelectionChange(mapKeysToRows(newSelected).filter(Boolean));
    }
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };

  const isSelected = (key: string) => selected.indexOf(key) !== -1;
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const getLabelDisplayedRowsTo = () => {
    if (data.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1 ? data.length : Math.min(data.length, (page + 1) * rowsPerPage);
  };

  const theme = useTheme();

  return (
    <>
      <Table aria-label="dynamic table">
        <thead>
          <tr>
            <th>
              <Checkbox
                indeterminate={
                  selected.length > 0 &&
                  selected.length < data.filter((row) => !(currentUser && row.id === currentUser.id)).length
                }
                checked={
                  data.filter((row) => !(currentUser && row.id === currentUser.id)).length > 0 &&
                  selected.length === data.filter((row) => !(currentUser && row.id === currentUser.id)).length
                }
                onChange={handleSelectAllClick}
              />
            </th>
            {columns.map((column) => (
              <th key={column.key} style={{ padding: '8px', textAlign: 'left' }}>
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
            const key = generateKey(row);
            const isItemSelected = isSelected(key);
            const isCurrentUser = currentUser ? row.id === currentUser.id : false;

            return (
              <tr
                key={key}
                onClick={(event) => handleClick(event, key, row)}
                style={{
                  backgroundColor: isItemSelected ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
                }}
              >
                <td>
                  <Checkbox checked={isItemSelected} />
                </td>
                {columns.map((column) => (
                  <td key={column.key} style={{ padding: '8px' }}>
                    {column.render ? column.render(row[column.key], row) : row[column.key]}
                    {isCurrentUser && column.key === 'name' && (
                      <span
                        className="rounded-full ml-1 px-3 py-1"
                        style={{
                          backgroundColor: theme.palette.success[100],
                          color: theme.palette.success[700],
                        }}
                      >
                        You
                      </span>
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
          {emptyRows > 0 && (
            <tr style={{ height: 53 * emptyRows }}>
              <td colSpan={columns.length + 1} />
            </tr>
          )}
        </tbody>
      </Table>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          justifyContent: 'flex-end',
          mt: 2,
        }}
      >
        <Select onChange={handleChangeRowsPerPage} value={rowsPerPage}>
          <Option value={10}>10</Option>
          <Option value={25}>25</Option>
        </Select>
        <Typography sx={{ textAlign: 'center', minWidth: 80 }}>
          {`${page * rowsPerPage + 1}-${getLabelDisplayedRowsTo()} of ${data.length}`}
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton size="sm" disabled={page === 0} onClick={() => handleChangePage(page - 1)}>
            <KeyboardArrowLeftIcon />
          </IconButton>
          <IconButton
            size="sm"
            disabled={data.length !== -1 ? page >= Math.ceil(data.length / rowsPerPage) - 1 : false}
            onClick={() => handleChangePage(page + 1)}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default DynamicTable;
