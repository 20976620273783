import { TextInputField } from './TextInputField';
import { CreateAccountFormFields } from '../types/CreateAccountFormFields';
import getAccountFormSchema from '../schema/getAccountFormSchema';
import { useTheme } from '@mui/joy';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import LanguageSwitcher from './LanguageSwitcher';

interface CreateAccountFormProps {
  disableEmail: boolean;
  buttonText: string;
  email?: string;
  formData: CreateAccountFormFields;
  setFormData: (formData: CreateAccountFormFields) => void;
  errors: Record<string, string>;
  setErrors: (errors: Record<string, string>) => void;
  onSubmit: () => void;
}

export const CreateAccountForm: React.FC<CreateAccountFormProps> = ({
  disableEmail,
  buttonText,
  email,
  formData,
  setFormData,
  errors,
  setErrors,
  onSubmit,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const validateForm = async (): Promise<boolean> => {
    const schema = getAccountFormSchema(t);
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (validationErrors) {
      if (validationErrors instanceof Yup.ValidationError) {
        const formErrors: Record<string, string> = {};
        validationErrors.inner.forEach((error) => {
          if (error.path) {
            formErrors[error.path] = error.message;
          }
        });
        setErrors(formErrors);
      }
      return false;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const isValid = await validateForm();
    if (isValid) {
      onSubmit();
    }
  };

  return (
    <div className="min-h-screen flex flex-col sm:flex-row" style={{ background: theme.palette.neutral[100] }}>
      <div className="absolute top-0 right-0 p-4">
        <LanguageSwitcher />
      </div>
      <div className="relative sm:w-3/5 flex-shrink-0">
        <img
          className="absolute top-0 left-0 h-5 w-28 font-medium m-5 z-10"
          src="images/logos/logo.png"
          alt="Sifly Logo"
        />
        <video autoPlay muted loop className="h-[50vh] sm:min-h-full max-w-full object-fill sm:object-none">
          <source src="/videos/siflylogin.mp4" type="video/mp4" />
        </video>
      </div>

      <div className="flex items-center justify-center sm:min-h-[90vh] p-6 sm:w-2/5">
        <div className="max-w-[28rem]">
          <h2
            className="text-4xl leading-[3rem] font-semibold mb-6"
            style={{ color: theme.palette.neutral[800], fontFamily: theme.fontFamily.display }}
          >
            {t('signup_page.create_sifly_account')}
          </h2>
          <form onSubmit={handleSubmit}>
            <TextInputField
              label={t('signup_page.email_address')}
              name="email"
              value={formData.email}
              placeholder={t('signup_page.email_address')}
              onChange={handleChange}
              error={errors.email}
              disabled={disableEmail}
              classNames="w-full px-4 py-3 border rounded-md text-lg min-h-12"
            />
            <div className="flex justify-between">
              <TextInputField
                label={t('signup_page.first_name')}
                name="firstName"
                placeholder={t('signup_page.first_name')}
                value={formData.firstName}
                onChange={handleChange}
                error={errors.firstName}
                classNames="w-full px-4 py-3 border rounded-md text-lg min-h-12"
              />
              <TextInputField
                label={t('signup_page.last_name')}
                name="lastName"
                placeholder={t('signup_page.last_name')}
                value={formData.lastName}
                onChange={handleChange}
                error={errors.lastName}
                classNames="w-full px-4 py-3 border rounded-md text-lg min-h-12"
              />
            </div>
            <TextInputField
              label={t('signup_page.call_sign')}
              name="callSign"
              placeholder={t('signup_page.call_sign')}
              value={formData.callSign}
              onChange={handleChange}
              error={errors.callSign}
              classNames="w-full px-4 py-3 border rounded-md text-lg min-h-12"
            />
            <TextInputField
              label={t('signup_page.password')}
              name="password"
              placeholder={t('signup_page.password')}
              type="password"
              value={formData.password}
              onChange={handleChange}
              error={errors.password}
              classNames="w-full px-4 py-3 border rounded-md text-lg min-h-12"
            />
            <TextInputField
              label={t('signup_page.confirm_password')}
              name="confirmPassword"
              placeholder={t('signup_page.confirm_password')}
              type="password"
              value={formData.confirmPassword}
              onChange={handleChange}
              error={errors.confirmPassword}
              classNames="w-full px-4 py-3 border rounded-md text-lg min-h-12"
            />
            <button
              type="submit"
              className="cursor-pointer w-full bg-black text-white rounded-md hover:bg-gray-700 transition duration-200 py-2 px-6 mt-4 text-base leading-4 font-normal h-12"
            >
              {buttonText}
            </button>
          </form>

          <div className="mt-4 text-center">
            <span className="flex justify-center font-normal text-base">
              <p className="text-black mr-2">{t('signup_page.already_have_account')} </p>
              <Link to={'/'} className="underline" style={{ color: theme.palette.neutral[700] }}>
                {t('login_page.sign_in')}
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
