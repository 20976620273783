import { ApiResult } from '../models/ApiResult';
import {
  DroneModel,
  DroneModelBackend,
  DroneModelEditBasic,
  DroneModelEditConfig,
  DroneModelPatch,
  FullDroneModelInput,
} from '../models/DroneModel/DroneModel.model';
import { apiClient } from './ApiService';
import { serializeDroneModelFromBackend } from './serializers/DroneModelSerializer';


/**
 * Internal function to handle drone model creation.
 * @param droneModel - The full drone model input.
 * @returns A promise resolving to the created DroneModel.
 */
const createDroneModel = (droneModel: FullDroneModelInput): Promise<ApiResult<DroneModel>> => {
  return apiClient.post<DroneModelBackend>('/drone_commander/drone-models/', droneModel).then((backendModel) => {
    return {
      success: true,
      data: serializeDroneModelFromBackend(backendModel),
    };
  });
};

const getDroneModels = async (): Promise<DroneModel[]> => {
  return apiClient.get<DroneModelBackend[]>('/drone_commander/drone-models/', {}, true).then((backendModels) => {
    const droneModels = backendModels.map((model) => {
      return serializeDroneModelFromBackend(model);
    });
    return droneModels;
  });
};

/**
 * Internal function to handle drone model updates.
 * @param input - The edit input (basic or config).
 * @returns A promise resolving to the updated DroneModel.
 */
const updateDroneModel = (droneModel: DroneModelPatch): Promise<ApiResult<DroneModel>> => {
  return apiClient
    .patch<DroneModelBackend>(`/drone_commander/drone-models/${droneModel.id}/`, droneModel)
    .then((model) => {
      if (model) {
        return { success: true, data: serializeDroneModelFromBackend(model) };
      } else {
        throw new Error('Drone model not found');
      }
    });
};

const updateDroneModelBasic = (droneModel: DroneModelEditBasic): Promise<ApiResult<DroneModel>> => {
  return updateDroneModel(droneModel);
};

const updateDroneModelConfig = (droneModel: DroneModelEditConfig): Promise<ApiResult<DroneModel>> => {
  return updateDroneModel(droneModel);
};

const deleteDroneModel = (id: string): Promise<ApiResult<Record<string, never>>> => {
  return apiClient.delete<DroneModelBackend[]>(`/drone_commander/drone-models/${id}`, {}).then((response) => {
    if (response) {
      console.log('Drone model deleted');
      return { success: true, data: {} };
    } else {
      throw new Error('Drone model not found');
    }
  });
};

const getDroneModelById = async (id: string): Promise<ApiResult<DroneModel | undefined>> => {
  return apiClient.get<DroneModelBackend>(`/drone_commander/drone-models/${id}/`, {}, true).then((model) => {
    if (model) {
      return { success: true, data: serializeDroneModelFromBackend(model) };
    } else {
      return { success: true, data: undefined };
    }
  });
};

const assignUUID = () => {};

const getUUIDAssignments = () => {};

export const DroneModelService = {
  createDroneModel,
  getDroneModels,
  getDroneModelById,
  deleteDroneModel,
  assignUUID,
  getUUIDAssignments,
  updateDroneModelBasic,
  updateDroneModelConfig,
};
