import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/joy';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserService } from '../../services/UserService';
import User from '../../models/User';
import { toast } from 'react-toastify';
import { TextInputField } from '../../components/TextInputField';
import { useTranslation } from 'react-i18next';
import { getPasswordChangeSchema } from '../../schema/passwordChangeSchema';
import { UserProfileCard } from '../../components/UserProfileCard';

interface PasswordChangeCardProps {
  userData: User | null;
}

interface PasswordChangeForm {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

export const PasswordChangeCard: React.FC<PasswordChangeCardProps> = ({ userData }) => {
  const { t } = useTranslation();
  const schema = getPasswordChangeSchema(t);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema),
  });

  const [isChangingPassword, setIsChangingPassword] = useState(false);

  useEffect(() => {
    reset({
      currentPassword: '',
      password: '',
      confirmPassword: '',
    });
  }, [userData, reset]);

  const handleChangePassword = () => setIsChangingPassword(true);

  const handleCancelChange = () => {
    setIsChangingPassword(false);
    reset();
  };

  const onSubmit: SubmitHandler<PasswordChangeForm> = async (data: PasswordChangeForm) => {
    try {
      if (userData) {
        await UserService.changeUsernamePassword(userData.id, data.currentPassword, data.password);
        setIsChangingPassword(false);
        toast.success(t('edit_profile.password_updated'));
      }
    } catch (error) {
      setIsChangingPassword(false);
      toast.error(`${t('edit_profile.errors.update_password')}: ${error}`);
    }
  };

  return (
    <UserProfileCard>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography level="h3">{t('edit_profile.password_change')}</Typography>
        {!isChangingPassword && (
          <Button variant="outlined" color="neutral" onClick={handleChangePassword}>
            {t('edit_profile.change_password')}
          </Button>
        )}
      </Box>
      <Typography sx={{ pb: 2 }}>{t('edit_profile.password_validation')}</Typography>

      {isChangingPassword && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="currentPassword"
            control={control}
            render={({ field }) => (
              <TextInputField
                label={t('edit_profile.current_password')}
                type="password"
                {...field}
                error={errors.currentPassword ? errors.currentPassword.message : ''}
                classNames="lg:w-1/2"
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextInputField
                label={t('edit_profile.new_password')}
                type="password"
                {...field}
                error={errors.password ? errors.password.message : ''}
                classNames="lg:w-1/2"
              />
            )}
          />

          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <TextInputField
                label={t('signup_page.confirm_password')}
                type="password"
                {...field}
                error={errors.confirmPassword ? errors.confirmPassword.message : ''}
                classNames="lg:w-1/2"
              />
            )}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2 }}>
            <Button type="submit" color="neutral" disabled={isSubmitting}>
              {isSubmitting ? t('edit_profile.saving') : t('edit_profile.update_password')}
            </Button>
            <Button variant="outlined" color="neutral" onClick={handleCancelChange}>
              {t('edit_profile.cancel')}
            </Button>
          </Box>
        </form>
      )}
    </UserProfileCard>
  );
};
