import { FC, memo } from 'react';
import { DroneModel } from '../../models/DroneModel/DroneModel.model';
import { Box, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';

interface DronePropertiesViewProps {
  drone: DroneModel;
}

const DronePropertiesView: FC<DronePropertiesViewProps> = ({ drone }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Typography level="body-md" fontWeight="bold">
          {t('drone_models.properties_label.name')}:
        </Typography>
        <Typography level="body-md">{drone.name}</Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography level="body-md" fontWeight="bold">
          {t('drone_models.properties_label.manufacturer')}:
        </Typography>
        <Typography level="body-md">{drone.manufacturer}</Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography level="body-md" fontWeight="bold">
          {t('drone_models.properties_label.type')}:
        </Typography>
        <Typography level="body-md">{drone.type}</Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography level="body-md" fontWeight="bold">
          {t('drone_models.properties_label.description')}
        </Typography>
        <Typography level="body-md">{drone.description || t('drone_modesls.properties.no_description')}</Typography>
      </Box>
    </Box>
  );
};

export default memo(DronePropertiesView);
