import React, { useEffect, useState } from 'react';
import { DroneModelService } from '../../services/DroneModelService';
import { DroneModel } from '../../models/DroneModel/DroneModel.model';
import { Box, Button, CircularProgress, Typography } from '@mui/joy';
import ResponsiveLayout from '../../components/layout/ResponsiveLayout';
import DroneModelsTable from '../../components/DroneModels/DroneModelsTable';
import { PageHeaderTitle } from '../../components/PageHeaderTitle';
import { PageBody } from '../../components/PageBody';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DroneModelList = () => {
  const [drones, setDrones] = useState<DroneModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchDrones = async () => {
      const data = await DroneModelService.getDroneModels();
      setDrones(data);
      setLoading(false);
    };
    fetchDrones();
  }, []);

  const handleView = (drone: DroneModel) => {
    navigate(`/drone-model/${drone.id}`, {
      state: { drone, isEdit: false },
    });
  };

  const getTable = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      );
    }
    if (drones.length === 0) {
      return <Typography>{t('drone_models.errors.no_drone_models')}</Typography>;
    }
    return (
      <DroneModelsTable
        data={drones}
        selectedRows={[]}
        onRowClick={(row) => {
          navigate(`/drone-model/${row.id}`, {
            state: { drone: row, isEdit: false },
          });
        }}
        onView={handleView}
      />
    );
  };

  const header = (
    <PageHeaderTitle
      title={t('drone_models.list_page.title')}
      pageExplanation={t('drone_models.list_page.page_description')}
      headerControls={() => (
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => {
              navigate('/drone-models/new');
            }}
          >
            {t('drone_models.buttons.create_drone_model')}
          </Button>
        </Box>
      )}
    />
  );

  return (
    <ResponsiveLayout>
      <PageBody header={header}>{getTable()}</PageBody>
    </ResponsiveLayout>
  );
};

export default DroneModelList;
