import React from 'react';
import { useTranslation } from 'react-i18next';

const DroneDetails = () => {
  const { t } = useTranslation();

  return <div> {t('drone_details.details')} </div>;
};

export default DroneDetails;
