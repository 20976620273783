import React, { ReactNode } from 'react';
import { useMediaQuery } from '@mui/material';
import TwoColumnLayout from './TwoColumnLayout';
import SingleColumnLayout from './SingleColumnLayout';
import BreadcrumbLayout from './BreadcrumbLayout';
import { BreadcrumbItem } from '../../models/BreadcrumbItem';

interface ResponsiveLayoutProps {
  breadcrumbItems?: BreadcrumbItem[];
  children: ReactNode;
}

const ResponsiveLayout: React.FC<ResponsiveLayoutProps> = ({ children, breadcrumbItems = [] }) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isBreadcrumbLayout = breadcrumbItems.length > 0;

  if (isSmallScreen) {
    return <SingleColumnLayout>{children}</SingleColumnLayout>;
  } else if (isBreadcrumbLayout) {
    return <BreadcrumbLayout breadcrumbItems={breadcrumbItems}>{children}</BreadcrumbLayout>;
  } else {
    return <TwoColumnLayout>{children}</TwoColumnLayout>;
  }
};

export default ResponsiveLayout;
