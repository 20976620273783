import React, { useEffect } from 'react';
import { useColorScheme } from '@mui/joy/styles';
import sessionStore from '../stores/SessionStore';
import { useLocation } from 'react-router-dom';
import { useCustomTheme } from '../contexts/ThemeContext';

const ThemeManager: React.FC = () => {
  const { setMode } = useColorScheme();
  const location = useLocation();
  const { mode } = useCustomTheme();

  const applyTheme = (theme: string) => {
    if (theme === 'default') {
      const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    } else {
      setMode(theme === 'light' ? 'light' : 'dark');
    }
  };

  useEffect(() => {
    const preferredTheme = sessionStore.user?.color_scheme_preference || 'default';
    applyTheme(preferredTheme);

    const systemThemeListener = window.matchMedia('(prefers-color-scheme: dark)');

    const handleThemeChange = (event: MediaQueryListEvent) => {
      if (sessionStore.user?.color_scheme_preference === 'default') {
        applyTheme(event.matches ? 'dark' : 'light');
      }
    };

    systemThemeListener.addEventListener('change', handleThemeChange);

    return () => {
      systemThemeListener.removeEventListener('change', handleThemeChange);
    };
  }, [setMode]);

  useEffect(() => {
    applyTheme(mode);
  }, [mode]);

  return null;
};

export default ThemeManager;
