import React, { useEffect, useState } from 'react';
import { Button, Table } from '@mui/joy';
import User from '../models/User';
import { UserService } from '../services/UserService';
import { useTranslation } from 'react-i18next';

const UserList: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersData = await UserService.getUsers();
        setUsers(usersData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setError(t('user_list.errors.fetch_users'));
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  const handleRemoveOrganization = async (userId: number) => {
    try {
      await UserService.removeOrganization(userId);
      setUsers((prevUsers) => prevUsers.map((user) => (user.id === userId ? { ...user, organization: null } : user)));
    } catch (error) {
      console.error('Error removing organization:', error);
      setError(t('user_list.errors.remove_org'));
    }
  };

  if (loading) return <p>{t('user_list.loading')}</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4 text-center">{t('user_list.user_list')}</h2>
      <div className="p-4">
        <Table>
          <thead>
            <tr>
              <th className="text-gray-800">{t('user_list.email')}</th>
              <th className="text-gray-800">{t('user_list.username')}</th>
              <th className="text-gray-800">{t('user_list.org')}</th>
              <th className="text-gray-800">{t('user_list.actions')}</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td className="text-gray-800">{user.email}</td>
                <td className="text-gray-800">{user.username}</td>
                <td className="text-gray-800">{user.organization ? user.organization : t('user_list.none')}</td>
                <td>
                  {user.organization && (
                    <Button variant="solid" color="danger" onClick={() => handleRemoveOrganization(user.id)}>
                      {t('user_list.remove_org')}
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default UserList;
