import { toast } from 'react-toastify';
import {
  ServerException,
  ValidationException,
  ErrorResponse,
  InvalidSessionException,
  ForbiddenException,
} from './Exceptions';
import sessionStore from '../stores/SessionStore';
import { redirect } from 'react-router-dom';

export async function makeRequest<T>(
  baseURL: string,
  url: string,
  options: RequestInit,
  authRequired: boolean,
): Promise<T> {
  const csrfToken = getCSRFToken();

  const fetchOptions: RequestInit = {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...(csrfToken ? { 'X-CSRFToken': csrfToken } : {}),
      ...(options.headers || {}),
    },
  };

  if (authRequired) {
    fetchOptions.credentials = 'include';
  }

  try {
    const response = await fetch(`${baseURL}${url}`, fetchOptions);
    if (!response.ok) {
      const errorResponse: ErrorResponse = await response.json();

      switch (response.status) {
        case 401:
          throw InvalidSessionException.fromErrorResponse(errorResponse);
        case 403:
          // TODO: discuss how to handle forbidden; maybe redirect to forbidden page.
          throw ForbiddenException.fromErrorResponse(errorResponse);
        case 422:
        case 400:
          // validation error
          throw ValidationException.fromErrorResponse(errorResponse);
        case 404:
          toast.error('Not Found');
          break;
        case 500:
        case 408:
          throw ServerException.fromErrorResponse(errorResponse);
        default:
          throw new Error('Unexpected error');
      }
    }
    if (response.status === 204) {
      return {} as T; // No content
    }

    return response.json();
  } catch (error: any) {
    if (error instanceof InvalidSessionException) {
      sessionStore.clearSession();
      redirect('/login');
    }
    if (error instanceof ForbiddenException) {
      redirect('/forbidden');
    }
    throw error;
  }
}

function getCSRFToken() {
  const name = 'csrftoken'; // Django's default CSRF token cookie name
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return decodeURIComponent(cookie.substring(name.length + 1));
    }
  }
  return null;
}
