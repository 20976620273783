import { useState } from 'react';
import { Box, Typography, Button } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import ResponsiveLayout from '../../components/layout/ResponsiveLayout';
import { UserDetailsForm } from './UserDetailsForm';
import { PasswordChangeCard } from './PasswordChangeCard';
import { ThemePreferenceCard } from './ThemePreferenceCard';
import { useTranslation } from 'react-i18next';
import sessionStore from '../../stores/SessionStore';

export const AccountPreferences = () => {
  const userData = sessionStore.user;
  const [theme, setTheme] = useState(userData?.color_scheme_preference || 'default');
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEditDetails = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleSignOut = () => {
    navigate('/logout');
  };

  return (
    <ResponsiveLayout>
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '90%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 4,
            px: 2,
          }}
        >
          <Typography level="h2">{t('navigation.account_preferences')}</Typography>
          <Button variant="outlined" color="neutral" onClick={handleSignOut}>
            {t('edit_profile.sign_out')}
          </Button>
        </Box>

        <UserDetailsForm
          userData={userData}
          isEditing={isEditing}
          handleEditDetails={handleEditDetails}
          handleCancelEdit={handleCancelEdit}
        />

        <PasswordChangeCard userData={userData} />

        <ThemePreferenceCard theme={theme} setTheme={setTheme} />
      </Box>
    </ResponsiveLayout>
  );
};
